import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import Cookies from "js-cookie";
import axios from 'axios';
import MultipleBillsComponent from './MultipleBillsComponent';
import FileUploadComponent from '../../components/FileUploadComponent';
import ItalicTextDivider from "../../components/ItalicTextDivider";
import "./styles.css";
import {AttachFile, ContentCopy, PlayCircle} from "@mui/icons-material";
import {copyToClipboard, generateUUID, handleApiError, isEmptyString, isNull} from "../../utils/utils";
import Divider from "@mui/material/Divider";
import {axiosInstance, axiosMultiFormPostInstance} from "../../utils/axiosConfig";
import {notifyError, notifySuccess, notifyWarning} from "../../utils/display.notifications";
import {CREATE_PAYMENT_ENTRY_API, GENERATE_IRN, GET_PAYMENT_MODES_API} from "../../utils/consts";
import {FilesListView} from "../../components/FilesListView";
import {
    addBillUtil,
    appendAdditionalFilesInFormData,
    appendBillsInFormData,
    deleteBillUtils,
    handleAdditionalFilesChangeUtil,
    handleBillDataUpdateUtil,
    handleBillsFileChangeUtil,
    removeAdditionalFileEntryUtil,
    removeBillUtil
} from "./payment_form_utils";
import Backdrop from "@mui/material/Backdrop";
import {fetchInvoiceNumberList, fetchVendorsList, getVendorSelectionComponent} from "../utils";
import CreateVendor from "../CreateVendorPopup";

const CreatePaymentForm = () => {
    const startId = generateUUID();
    const startingBill = {};
    startingBill[startId] = {id: startId, name: "", entries: {}};

    const [bills, setBills] = useState(startingBill);
    const [paymentModes, setPaymentModes] = useState([])
    const [vendorList, setVendorList] = useState([])
    const [vendorListSearchMeta, setVendorListSearchMeta] = useState({
        searchQuery: '',
        maxEntries: 100,
    });
    const [invoiceListSearchMeta, setInvoiceListSearchMeta] = useState({
        searchQuery: ''
    });
    const [invoiceList, setInvoiceList] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [additionalFiles, setAdditionalFiles] = useState({})
    const [generatedIRN, setGeneratedIRN] = useState(null);

    // Status flags
    const [savingDetails, setSavingDetails] = useState(false)
    const [showCreateVendorPopup, setShowCreateVendorPopup] = useState(false);

    const fetchPaymentModes = async () => {
        try {
            setLoading(true);
            const accessToken = Cookies.get('access_token');
            const response = await axios.get(GET_PAYMENT_MODES_API, {
                headers: {
                    Authorization: `Token ${accessToken}`
                }
            });
            setPaymentModes(response.data);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    const generateIRN = () => {
        axiosInstance.get(GENERATE_IRN)
            .then((response) => {
                setGeneratedIRN(response.data.irn);
                notifySuccess("createpayment", "IRN Generated");
            })
            .catch((err) => {
                console.log("ERROR in generating IRN: ", err);
                notifyError("createpayment", "Error generating IRN");
            })
    }


    useEffect(() => {
        fetchPaymentModes();
    }, []);

    useEffect(() => {
        fetchVendorsList(setVendorList, vendorListSearchMeta.searchQuery, vendorListSearchMeta.maxEntries);
    }, [vendorListSearchMeta]);

    useEffect(() => {
        setLoading(true);
        setInvoiceList([]);
        fetchInvoiceNumberList(setInvoiceList, invoiceListSearchMeta.searchQuery, setLoading);
    }, [invoiceListSearchMeta]);

    const handleInvoiceListSearchChange = (e) => {
        setInvoiceListSearchMeta({
            ...invoiceListSearchMeta,
            searchQuery: e.target.value,
        });
    }

    const [formData, setFormData] = useState({
        paymentMode: '',
        paymentReferenceNumber: '',
        amount: '',
        description: '',
        billRequired: 'yes',
        vendor: null
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Calling Submit button");

        if (isEmptyString(formData.paymentMode)) {
            notifyError("createpayment", "Please select a payment mode.");
            return;
        }

        if (isEmptyString(formData.paymentReferenceNumber) && isNull(generatedIRN)) {
            notifyError("createpayment", "Either IRN need to be generated or enter transaction ID");
            return
        }

        const fileFormsData = new FormData();
        const data = {
            paymentMode: formData.paymentMode,
            paymentReferenceNumber: formData.paymentReferenceNumber,
            amount: formData.amount,
            description: formData.description,
            irn: generatedIRN,
            billRequired: formData.billRequired
        };

        if (!isNull(formData.vendor)) {
            data["vendorId"] = formData.vendor.id;
        }

        appendAdditionalFilesInFormData(additionalFiles, fileFormsData);
        appendBillsInFormData(bills, fileFormsData);

        Object.keys(data).forEach((key) => {
            fileFormsData.set(key, data[key]);
        });

        setSavingDetails(true);
        axiosMultiFormPostInstance.post(CREATE_PAYMENT_ENTRY_API, fileFormsData)
            .then((response) => {
                if (response.status < 300) {
                    console.log(response);
                    let timeout = 500;
                    notifySuccess("createpayment-success", "Payment recorded succesfully.");
                    if (response.data.bill_creation_failed) {
                        notifyWarning("createpayment-bill", "Bill Creation failed, please upload bill for added payment entry from edit tab.")
                        timeout = 1500;
                    }
                    if (response.data.additional_files_upload_failed) {
                        notifyWarning("createpayment-additional-file", "Failed to upload additional files for added payment, please add additional files again.")
                        timeout = 1500;
                    }
                    if (response.data.bill_files_upload_failed) {
                        notifyWarning("createpayment-bill-file", "Failed to upload bill files for added payment, please add additional files again.")
                        timeout = 1500;
                    }
                    setTimeout(() => {
                        window.location.href = "/pay-now";
                    }, timeout)
                } else {
                    notifyError("createpayment-error", "Failed to create payment entry, please retry.");
                }
            })
            .catch((err) => {
                const errNotificationId = "createpayment-error";
                console.error(err);
                handleApiError(errNotificationId, err);
            })
            .finally(() => {
                setSavingDetails(false);
            })
    };

    const addBill = () => {
        addBillUtil(bills, setBills);
    };

    const removeBill = (id) => {
        removeBillUtil(id, bills, setBills);
    }

    const handleBillNameUpdate = (billId, name) => {
        handleBillDataUpdateUtil(billId, "name", name, bills, setBills);
    }

    const handleAccountChange = (billId, account_details) => {
        handleBillDataUpdateUtil(billId, "account", account_details, bills, setBills);
    }

    const handleBatchChange = (billId, batch_details) => {
        handleBillDataUpdateUtil(billId, "batchNumber", batch_details, bills, setBills);
    }

    const handleBillDescriptionUpdate = (billId, description) => {
        handleBillDataUpdateUtil(billId, "description", description, bills, setBills);
    }

    const deleteBillEntry = (billId, entryId) => {
        deleteBillUtils(billId, entryId, bills, setBills);
    }

    const handleBillsFileChange = (files, billId) => {
        handleBillsFileChangeUtil(files, billId, bills, setBills);
    }

    const removeAdditionalFileEntry = (id) => {
        removeAdditionalFileEntryUtil(id, additionalFiles, setAdditionalFiles);
    }

    const handleAdditionalFilesChange = (files) => {
        handleAdditionalFilesChangeUtil(files, additionalFiles, setAdditionalFiles);
    }

    const handleVendorListSearchChange = (e) => {
        setVendorListSearchMeta({
            ...vendorListSearchMeta,
            searchQuery: e.target.value,
        });
    }

    const handleVendorSelection = (vendor) => {
        setFormData({
            ...formData,
            vendor: vendor
        })
    }

    let additionalFilesView = null;
    if (Object.keys(additionalFiles).length > 0) {
        additionalFilesView = (
            <>
                <ItalicTextDivider text={"Newly Added"}/>
                <div style={{overflowY: 'scroll', maxHeight: "200px"}}>
                    <FilesListView
                        removeHandler={removeAdditionalFileEntry}
                        files={Object.values(additionalFiles)}
                    />
                </div>
            </>
        )
    }

    const transactionIdLabel = formData.paymentMode ?
        paymentModes.find(mode => mode.key === formData.paymentMode)?.ref_matcher :
        "First choose a Payment Method";

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{width: "100%", marginRight: "100px"}}
        >
            <Typography variant='h5'>Add New Payment</Typography>
            <Divider style={{marginTop: "10px", marginBottom: "10px"}}></Divider>
            <Grid container spacing={2} sx={{height: "700px", overflowY: 'scroll'}}>
                <Grid item xs={12} sm={4}>
                    <div className={"payment-details-box card-box-shadow"} style={{padding: 16}}>
                        <Typography style={{marginBottom: 10}} variant='h6'>Payment Details</Typography>
                        <Divider></Divider>
                        <Typography sx={{mt: 2}} variant="body2" fontStyle="italic">
                            Use Below IRN, wherever transaction remarks can be added (ex. Enkash Vendor Payment, ICICI
                            Net Banking). If IRN is used then can skip Transaction ID
                        </Typography>
                        <Box sx={{display: "flex", alignItems: "center", mb: 2}}>
                            <Typography sx={{
                                fontStyle: "italic", paddingLeft: "8px", paddingRight: "8px",
                                borderRadius: "4px",
                                backgroundColor: isNull(generatedIRN) ? "" : "#196F3D",
                                color: isNull(generatedIRN) ? "#000" : "white"
                            }}
                            >
                                {
                                    isNull(generatedIRN) ? "Generate Internal Ref No." : `Generated IRN: ${generatedIRN}`
                                }
                            </Typography>
                            {
                                isNull(generatedIRN) ? (
                                    <IconButton onClick={generateIRN}>
                                        <PlayCircle sx={{color: "#196F3D"}}></PlayCircle>
                                    </IconButton>) : null
                            }
                            {
                                isNull(generatedIRN) ? null : (
                                    <IconButton onClick={() => copyToClipboard(generatedIRN)} sx={{marginLeft: "10px"}}>
                                        <ContentCopy></ContentCopy>
                                    </IconButton>
                                )
                            }
                        </Box>
                        <FormControl fullWidth>
                            <InputLabel id="payment-method-label">Payment Method</InputLabel>
                            <Select
                                labelId="payment-method-label"
                                name="paymentMode"
                                value={formData.paymentMode}
                                onChange={handleChange}
                                label="Payment Method"
                            >
                                {paymentModes.map((mode) => (
                                    <MenuItem key={mode.key} value={mode.key}>
                                        {mode.key}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography style={{marginTop: 23}} variant='caption'>
                            {formData.paymentMode ? `Find "${transactionIdLabel}" number from Bank/Transaction statement.` : transactionIdLabel}
                        </Typography>
                        <TextField
                            disabled={!formData.paymentMode}
                            label={transactionIdLabel}
                            name="paymentReferenceNumber"
                            value={formData.paymentReferenceNumber}
                            onChange={handleChange}
                            fullWidth
                            sx={{mt: 2}}
                            style={{marginTop: "5px"}}
                        />
                        <TextField
                            label="Amount"
                            name="amount"
                            type="number"
                            value={formData.amount}
                            onChange={handleChange}
                            fullWidth
                            sx={{mt: 1, mb: 1}}
                        />

                        <FormControl fullWidth>
                            <InputLabel sx={{padding: "0px !important"}} id="bill-required">Bill Required ? (e.g.
                                Porter/Google ads wallet recharge do not have bill)</InputLabel>
                            <Select
                                fullWidth
                                labelId="bill-required"
                                label="Bill Required ? (e.g. Porter/Google ads wallet recharge do not have bill)"
                                name="billRequired"
                                value={formData.billRequired}
                                onChange={handleChange}
                                inputProps={{
                                    sx: {padding: "8px !important"}
                                }}
                                sx={{mb: 1}}
                            >
                                <MenuItem key={'yes'} value={'yes'}>Yes</MenuItem>
                                <MenuItem key={'no'} value={'no'}>No</MenuItem>
                            </Select>
                        </FormControl>
                        {getVendorSelectionComponent(vendorList, false, handleVendorSelection, handleVendorListSearchChange, setShowCreateVendorPopup, formData.vendor)}

                        <TextField
                            fullWidth
                            multiline
                            label="Description-Fill in Vendor Name, Invoice No. etc (Optional)"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            minRows={2}
                            sx={{mt: 2}}
                        />
                        <ItalicTextDivider text={"Additional files"}/>
                        {additionalFilesView}
                        <FileUploadComponent
                            buttonStyles={{
                                variant: "text", color: 'secondary',
                                sx: {marginTop: '15px'},
                                startIcon: <AttachFile/>,
                            }}
                            text={"Attach Additional Files"}
                            index={0}
                            files={additionalFiles}
                            showFiles={false}
                            handleFileChange={e => handleAdditionalFilesChange(Array.from(e.target.files))}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={8} style={{display: 'flex', flexDirection: 'column'}}>
                    <MultipleBillsComponent
                        handleBillNameUpdate={handleBillNameUpdate}
                        handleAccountChange={handleAccountChange}
                        handleBatchChange={handleBatchChange}
                        handleBillDescriptionUpdate={handleBillDescriptionUpdate}
                        bills={bills}
                        addBill={addBill}
                        removeBill={removeBill}
                        handleInvoiceListSearchChange={handleInvoiceListSearchChange}
                        invoiceList={invoiceList}
                        handleBillsFileChange={handleBillsFileChange}
                        deleteBillEntry={deleteBillEntry}
                    />
                </Grid>
            </Grid>
            <div style={{
                width: '100%', display: "flex", justifyContent: "center", alignItems: "center", marginTop: "50px"
            }}>
                <Button
                    disabled={savingDetails}
                    variant="contained"
                    color="primary"
                    type="submit"
                    size={"large"}
                    sx={{
                        height: "60px",
                        width: "60%"
                    }}
                >
                    {savingDetails ? <CircularProgress title={"Saving..."}/> : "Save"}
                </Button>
            </div>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: theme => theme.zIndex.drawer + 1,
                    display: loading ? 'flex' : 'none', // Ensuring Backdrop is shown/hidden based on `loading`
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                open={loading}
                // onClick={handleClose}
            >
                <CircularProgress color="primary"/>
            </Backdrop>
            <CreateVendor open={showCreateVendorPopup} onClose={() => setShowCreateVendorPopup(false)}/>
        </Box>
    );
};

export default CreatePaymentForm;