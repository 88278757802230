import {
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputAdornment,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import Divider from "@mui/material/Divider";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {axiosInstance} from "../../utils/axiosConfig";
import {
    CREATE_ENKASH_ENTRY_API,
    DELETE_FILE_ENTRY_API,
    DELETE_PAYMENT_ENTRY_API,
    EDIT_BILL_ENTRY_API,
    GET_BILL_ENTRY_API
} from "../../utils/consts";
import {notifyError, notifySuccess} from "../../utils/display.notifications";
import {ZohoDetails} from "../PaymentsForm/ZohoDetails";
import {FilesListView} from "../../components/FilesListView";
import FileUploadComponent from "../../components/FileUploadComponent";
import {PaymentFormCard} from "./PaymentFormCard";
import {
    accountSelector,
    batchNumberSelector,
    enkashVendorSelector,
    fetchAccountList,
    fetchAllPaymentModesUtil,
    fetchAllUsersUtil,
    fetchBatchList,
    fetchEnkashVendorList,
    fetchPaymentList,
    fetchVendorsList,
    getVendorSelectionComponent,
    paymentSelector
} from "../utils";
import LinkIcon from '@mui/icons-material/Link';
import ConfirmationModal from "../../components/ConfirmationModal";
import {copyToClipboard, generateUUID, isNull, notNull} from "../../utils/utils";
import {AddOutlined, ContentCopy} from "@mui/icons-material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Backdrop from "@mui/material/Backdrop";
import {
    handleAdditionalFilesForMultiplePayments,
    handleBillFilesForBillEntry,
    validatePaymentData
} from "../PaymentsForm/bill_form_utils";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {getUserSelectionBox} from "../PaymentsForm/payment_form_utils";
import CreateVendor from "../CreateVendorPopup";

export const EditBillForm = () => {

    const {id: billId} = useParams();

    const [billData, setBillData] = useState({
        bill_files: [],
        additional_files: [],
        zoho_details: {},
        payments: [],
        paymentDueDate: null,
        account: null,
        batchNumber: null,
        billName: null,
        proformaBillName: null,
        enkashVendorId: null,
        enkashInvoice: null,
        invoiceDueDate: null,
        enkashInvoiceDate: null,
        totalAmount: null,
        paymentDuePercentage: null,
        zohoVendor: null
    });
    const [openPaymentCard, setOpenPaymentCard] = useState(-1);
    const [paymentModes, setPaymentModes] = useState([]);
    const [enkashInformationString, setEnkashInformationString] = useState("");
    const [linkPaymentDetails, setLinkPaymentDetails] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [openPaymentSelector, setOpenPaymentSelector] = useState(false);
    const [paymentDeleteMeta, setPaymentDeleteMeta] = useState({
        confirmationModal: false,
        paymentId: null
    });
    const [billFileDeleteMeta, setBillFileDeleteMeta] = useState({
        confirmationModal: false,
        fileId: null
    });
    const [additionalFileDeleteMeta, setAdditionalFileDeleteMeta] = useState({
        confirmationModal: false,
        fileId: null
    });
    const [accountList, setAccountList] = useState([])
    const [batchList, setBatchList] = useState([])
    const [paymentList, setPaymentList] = useState([])
    const [accountListSearchMeta, setAccountListSearchMeta] = useState({
        searchQuery: ''
    });
    const [batchListSearchMeta, setBatchListSearchMeta] = useState({
        searchQuery: ''
    });
    const [paymentListSearchMeta, setPaymentListSearchMeta] = useState({
        searchQuery: ''
    });
    const [vendorListSearchMeta, setVendorListSearchMeta] = useState({
        searchQuery: ''
    });
    const [allUsers, setAllUsers] = useState([]);
    const [createdByUserId, setCreatedByUserId] = useState(null);

    const [newPaymentEntries, setNewPaymentEntries] = useState([]);
    const [savingDetails, setSavingDetails] = useState(false)
    const [loading, setLoading] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);
    const [enkashVendorList, setEnkashVendorList] = useState([])
    const [zohoVendorList, setZohoVendorList] = useState([])
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [showCreateVendorPopup, setShowCreateVendorPopup] = useState(false);
    const [cacheDataCompulsory, setCacheDataCompulsory] = useState(false);
    const [totalAmountNullInitally, setTotalAmountNullInitially] = useState(false);
    const [enkashVendorNullInitially, setEnkashVendorNullInitially] = useState(false);
    const [enkashInvoiceAmount, setEnkashInvoiceAmount] = useState(null);
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);
    const handleConfirmationOpen = () => setConfirmationOpen(true);
    const handleConfirmationClose = () => setConfirmationOpen(false);

    const handleEnkashInvoiceCreation = () => {
        if (notNull(billData.totalAmount) && notNull(enkashInvoiceAmount) &&
            notNull(billData.invoiceDueDate) && notNull(billData.enkashInvoiceDate) && notNull(billData.enkashVendorId)) {
            let postFormData = new FormData();
            postFormData.set("billId", billId);
            postFormData.set("paymentAmount", enkashInvoiceAmount);
            postFormData.set("invoiceDueDate", billData.invoiceDueDate);
            postFormData.set("enkashInvoiceDate", billData.enkashInvoiceDate);
            postFormData.set("enkashVendorId", billData.enkashVendorId);
            if (totalAmountNullInitally) {
                postFormData.set("totalAmount", billData.totalAmount);
            }
            postFormData.set("billLink", window.location.href)
            setSavingDetails(true);
            setLoading(true);
            axiosInstance.post(CREATE_ENKASH_ENTRY_API, postFormData)
                .then((response) => {
                    console.log("RESP: ", response);
                    fetchBillsData();
                    handleCloseDialog();
                    notifySuccess("invoicecreate", "Invoice Entry created successfully!");
                })
                .catch((err) => {
                    notifyError("invoicecreate-error", err.response.data.msg);
                    console.error(err);
                })
                .finally(() => {
                    setSavingDetails(false);
                    setLoading(false);
                    handleConfirmationClose();
                })
        } else {
            notifyError("invoicecreate-error", "Please Fill all required Details to Create Invoice on Enkash");
            handleConfirmationClose();
        }
    };

    const fetchAllUsers = () => {
        fetchAllUsersUtil(setAllUsers);
    }

    const [zohoVendorListSearchMeta, setZohoVendorListSearchMeta] = useState({
        searchQuery: '',
        maxEntries: 100,
    });

    useEffect(() => {
        fetchVendorsList(setZohoVendorList, zohoVendorListSearchMeta.searchQuery, zohoVendorListSearchMeta.maxEntries);
    }, [zohoVendorListSearchMeta]);

    useEffect(() => {
        fetchAccountList(setAccountList, accountListSearchMeta.searchQuery);
    }, [accountListSearchMeta]);

    useEffect(() => {
        setLoading(true);
        fetchEnkashVendorList(setEnkashVendorList, vendorListSearchMeta.searchQuery, cacheDataCompulsory, setLoading);
    }, [vendorListSearchMeta]);

    useEffect(() => {
        if (notNull(enkashVendorList) && enkashVendorList.length > 0 && !cacheDataCompulsory) {
            setCacheDataCompulsory(true);
        }
    }, [enkashVendorList]);

    useEffect(() => {
        const {totalAmount, enkashPaymentPercentage, enkashInvoice, invoiceDueDate, enkashInvoiceDate} = billData;

        let validValue = 0;
        if (totalAmount > 0 && enkashPaymentPercentage > 0) {
            validValue = Math.round(totalAmount * (enkashPaymentPercentage / 100) * 100) / 100;
        }
        setEnkashInvoiceAmount(validValue);

        // Generate the information string
        const informationString = (
            <Container>
                <Typography variant="h4" gutterBottom>
                    Clicking on Confirm will:
                </Typography>
                <List>
                    {enkashInvoice ? (
                        <ListItem>
                            <Typography variant="body1">
                                Overwrite the already attached enkash invoice with id = {enkashInvoice} and will attach
                                the newly created invoice with the current bill.
                                <Typography variant="body1" color="error">
                                    Warning: If the already attached enkash invoice is not paid/cancelled successfully
                                    until now, the creation of the new invoice will fail.
                                </Typography>
                            </Typography>
                        </ListItem>
                    ) : null}
                    {(validValue && invoiceDueDate) ? (
                        <ListItem>
                            <Typography variant="body1">
                                Create an enkash invoice with amount: {validValue}, due date
                                = {dayjs(invoiceDueDate).format('DD/MM/YYYY')}, invoice date
                                = {dayjs(enkashInvoiceDate).format('DD/MM/YYYY')}
                            </Typography>
                        </ListItem>
                    ) : (
                        <ListItem>
                            <Typography variant="body1">
                                Please fill all the details to create the invoice on Enkash
                            </Typography>
                        </ListItem>
                    )}
                </List>
            </Container>
        );

        setEnkashInformationString(informationString);
    }, [billData.totalAmount, billData.enkashPaymentPercentage, billData.enkashInvoice, billData.invoiceDueDate, billData.enkashInvoiceDate, setEnkashInvoiceAmount, setEnkashInformationString]);

    useEffect(() => {
        if (isNull(billData.invoiceDueDate)) {
            handleFieldChange("invoiceDueDate", dayjs(new Date()))
        }
    }, [billData.invoiceDueDate]);

    useEffect(() => {
        if (isNull(billData.enkashInvoiceDate)) {
            handleFieldChange("enkashInvoiceDate", dayjs(new Date()))
        }
    }, [billData.enkashInvoiceDate]);

    useEffect(() => {
        fetchBatchList(setBatchList, batchListSearchMeta.searchQuery);
    }, [batchListSearchMeta]);

    useEffect(() => {
        fetchPaymentList(setPaymentList, paymentListSearchMeta.searchQuery, billId);
    }, [paymentListSearchMeta]);

    useEffect(() => {
        fetchPaymentModes();
        fetchBillsData();
        fetchAllUsers();
    }, []);

    const handlePaymentListSearchChange = (e) => {
        setPaymentListSearchMeta({
            ...paymentListSearchMeta,
            searchQuery: e.target.value,
        });
    }

    const handleAccountListSearchChange = (e) => {
        setAccountListSearchMeta({
            ...accountListSearchMeta,
            searchQuery: e.target.value,
        });
    }

    const handleZohoVendorListSearchChange = (e) => {
        setZohoVendorListSearchMeta({
            ...zohoVendorListSearchMeta,
            searchQuery: e.target.value,
        });
    }

    const handleBatchListSearchChange = (e) => {
        setBatchListSearchMeta({
            ...batchListSearchMeta,
            searchQuery: e.target.value,
        });
    }

    const deleteBillFileEntry = () => {
        const id = billFileDeleteMeta.fileId;
        if (isNull(id)) {
            return;
        }
        // DB call to remove file.
        const query = {
            fileId: id,
        }
        axiosInstance.delete(DELETE_FILE_ENTRY_API, {params: query})
            .then((response) => {
                console.log(response);
                notifySuccess("editbill", response.data.msg);
                fetchBillsData();
            })
            .catch(err => {
                console.error(err);
                notifyError("editbill-error", err.response.data.msg);
            })
            .finally(() => {
                setBillFileDeleteMeta({
                    confirmationModal: false,
                    fileId: null
                })
            });
    }

    const deleteSavedPaymentEntry = () => {
        if (isNull(paymentDeleteMeta.paymentId)) {
            notifyError("editbill", "Invalid payment id, failed to remove");
            return;
        }

        const query = {
            paymentId: paymentDeleteMeta.paymentId,
        }
        axiosInstance.delete(DELETE_PAYMENT_ENTRY_API, {params: query})
            .then((response) => {
                console.log(response);
                notifySuccess("editbill", response.data.msg);
                fetchBillsData();
            })
            .catch(err => {
                console.error(err);
                notifyError("editbill-error", err.response.data.msg);
            })
            .finally(() => {
                setPaymentDeleteMeta({
                    confirmationModal: false,
                    paymentId: null
                });
            })
    }

    const updateBillDetails = () => {
        if (isNull(billData.account)) {
            notifyError("editbill", "Account Cannot be Empty!");
            return;
        }
        for (let index = 0; index < newPaymentEntries.length; index++) {
            const payment = newPaymentEntries[index];
            const dataValid = validatePaymentData(payment, "for new payment entry " + (index + 1));
            if (!dataValid) {
                console.error(`Invalid payment data at index ${index}`);
                return;
            }
        }
        let postFormData = new FormData();
        postFormData.set("billId", billId);

        if (billData.new_bill_files && billData.new_bill_files.length > 0) {
            postFormData = handleBillFilesForBillEntry(billData.new_bill_files, postFormData, "billFiles");
        }
        if (billData.new_additional_files && billData.new_additional_files.length > 0) {
            postFormData = handleBillFilesForBillEntry(billData.new_additional_files, postFormData, "additionalFiles");
        }
        for (let index = 0; index < newPaymentEntries.length; index++) {
            const paymentEntry = newPaymentEntries[index];
            const files = paymentEntry.additional_files;
            if (files && files.length > 0) {
                postFormData = handleAdditionalFilesForMultiplePayments(files, paymentEntry.id, postFormData);
            }
        }

        if (!isNull(billData.paymentDueDate)) {
            postFormData.append("paymentDueDate", new Date(billData.paymentDueDate).toISOString());
        }

        if (!isNull(billData.paymentDuePercentage)) {
            postFormData.append("paymentDuePercentage", billData.paymentDuePercentage);
        }

        if (!isNull(billData.totalAmount)) {
            postFormData.append("totalAmount", billData.totalAmount);
        }

        if (!isNull(billData.account)) {
            postFormData.append("account", JSON.stringify(billData.account));
        }

        postFormData.append("billName", billData.billName);

        postFormData.append("proformaBillName", billData.proformaBillName);

        if (!isNull(billData.zohoVendor)) {
            postFormData.append("zohoVendorId", billData.zohoVendor.id);
        }

        postFormData.append("batchNumber", JSON.stringify(billData.batchNumber));

        postFormData.set("payments_data", JSON.stringify(newPaymentEntries));
        setSavingDetails(true);
        setLoading(true);
        axiosInstance.post(EDIT_BILL_ENTRY_API, postFormData)
            .then((response) => {
                console.log("RESP: ", response);
                fetchBillsData();
                setNewPaymentEntries([]);
                setEditMode(false);
            })
            .catch((err) => {
                console.error(err);
                notifyError("editbill-error", err.response.data.msg);
            })
            .finally(() => {
                setSavingDetails(false);
                setLoading(false);
            })
    }

    const fetchPaymentModes = () => {
        fetchAllPaymentModesUtil(setPaymentModes)
    };

    const fetchBillsData = () => {
        const queryString = `?bill_id=${billId}`;
        axiosInstance.get(GET_BILL_ENTRY_API + queryString)
            .then(response => {
                const billData = response.data.bill_data;
                setBillData(billData)
                setCreatedByUserId(billData.createdBy);
                setTotalAmountNullInitially(isNull(billData.totalAmount))
                setEnkashVendorNullInitially(isNull(billData.enkashVendorId))
            })
            .catch(error => {
                notifyError("editbill", "Error in getting Bill data");
            });
    }

    const updateNewBillFiles = (e) => {
        setBillData({
            ...billData,
            new_bill_files: Array.from(e.target.files)
        })
    }

    const removeNewBillFiles = (id) => {
        const filesCopy = billData.new_bill_files;
        filesCopy.splice(id, 1)
        setBillData({
            ...billData,
            new_bill_files: Array.from(filesCopy)
        })
    }

    const updateNewAdditionalFiles = (e) => {
        setBillData({
            ...billData,
            new_additional_files: Array.from(e.target.files)
        })
    }

    const removeNewAdditionalFiles = (id) => {
        const filesCopy = billData.new_additional_files;
        filesCopy.splice(id, 1)
        setBillData({
            ...billData,
            new_additional_files: Array.from(filesCopy)
        })
    }

    const handleDueAmountChange = (e) => {
        const val = parseFloat(e.target.value);
        const validValue = val >= 0 ? val : e.target.value === "" ? null : 0
        handleFieldChange("totalAmount", validValue)
    };

    const handleVendorListSearchChange = (e) => {
        setVendorListSearchMeta({
            ...vendorListSearchMeta,
            searchQuery: e.target.value,
        });
    }

    const handleEnkashPercentageChange = (e) => {
        let validValue = "";
        const value = e.target.value;
        if (value !== "") {
            const floatValue = parseFloat(value);
            if (floatValue > billData.paymentDuePercentage) {
                validValue = billData.paymentDuePercentage;
            } else if (floatValue < 0) {
                validValue = billData.enkashPaymentPercentage || 0;
            } else {
                validValue = floatValue;
            }
        }

        setBillData({
            ...billData,
            enkashPaymentPercentage: validValue,
        })
    }

    const handleEnkashVendorIdChange = (enkashVendor) => {
        let enkashVendorId = null;
        if (enkashVendor) {
            enkashVendorId = enkashVendor.company_id;
        }
        setBillData({
            ...billData,
            enkashVendorId: enkashVendorId,
        })
    }

    const addNewPaymentEntry = () => {
        const paymentEntriesCopy = [...newPaymentEntries];
        paymentEntriesCopy.push({
            id: generateUUID(),
            payment_mode: '',
            transaction_number: '',
            amount: '',
            description: '',
            additional_files: []
        });
        setNewPaymentEntries(paymentEntriesCopy);
    }

    const linkExistingPaymentEntry = () => {
        const paymentEntriesCopy = [...newPaymentEntries];
        const exists = paymentEntriesCopy.some(entry => entry.id === linkPaymentDetails.id);

        if (!exists) {
            paymentEntriesCopy.push({
                id: linkPaymentDetails.id,
                existingPayment: true,
                payment_mode: '',
                transaction_number: linkPaymentDetails.name,
                amount: '',
                description: '',
                additional_files: []
            });
            setNewPaymentEntries(paymentEntriesCopy);
        }
        setLinkPaymentDetails(null)
        setOpenPaymentSelector(false)
    }

    const handleAdditionalFilesChange = (index, files) => {
        const newPaymentEntriesCopy = [...newPaymentEntries];
        if (index < newPaymentEntriesCopy.length) {
            newPaymentEntriesCopy[index]["additional_files"] = files;
        }
        setNewPaymentEntries(newPaymentEntriesCopy);
    }

    const handleFieldChange = (field, newValue) => {
        setBillData({
            ...billData,
            [field]: newValue
        });
    };

    const removePaymentEntry = (index) => {
        const newPaymentEntriesCopy = [...newPaymentEntries];
        if (index < newPaymentEntriesCopy.length) {
            newPaymentEntriesCopy.splice(index, 1);
            setNewPaymentEntries(newPaymentEntriesCopy);
        }
    }

    const handlePaymentFormChange = (index, event) => {
        console.log("handlePaymentFormChange ", index, event);
        const {name, value} = event.target;
        const newPaymentEntriesCopy = [...newPaymentEntries];
        if (index < newPaymentEntriesCopy.length) {
            newPaymentEntriesCopy[index][name] = value;
            setNewPaymentEntries(newPaymentEntriesCopy);
        }
    }

    const handleVendorNameSelection = (index, vendor) => {
        const newPaymentEntriesCopy = [...newPaymentEntries];
        if (index < newPaymentEntriesCopy.length) {
            newPaymentEntriesCopy[index]["vendor"] = vendor;
            setNewPaymentEntries(newPaymentEntriesCopy);
        }
    }

    const setGeneratedIRN = (index, irn) => {
        const newPaymentEntriesCopy = [...newPaymentEntries];
        if (index < newPaymentEntriesCopy.length) {
            newPaymentEntriesCopy[index]["generatedIRN"] = irn;
            setNewPaymentEntries(newPaymentEntriesCopy);
        }
    }

    const toggleCardExpand = (index, openState) => {
        const state = index === openPaymentCard;
        if (state) {
            setOpenPaymentCard(-1); // CLose it
        } else {
            setOpenPaymentCard(index);
        }
    }

    const handleFirstPaymentAmountChange = (e) => {
        const value = e.target.value;
        const pendingAmount = (billData.totalAmount * billData.paymentDuePercentage) / 100 || 0;
        let validValue = 0;

        if (value !== "") {
            const floatValue = parseFloat(value);
            if (!isNaN(floatValue)) {
                if (floatValue > pendingAmount) {
                    validValue = pendingAmount;
                } else if (floatValue < 0) {
                    validValue = enkashInvoiceAmount || 0; // Keep the previous value if negative
                } else {
                    validValue = floatValue;
                }
            }
        }

        // Update billData with the valid value and recalculate the payment percentage
        setBillData(prevData => ({
            ...prevData,
            enkashPaymentPercentage: ((validValue / prevData.totalAmount) * 100)
        }));
    };

    const updatePaidBy = (event) => {
        setCreatedByUserId(event.target.value);
    }

    return (
        <Box sx={{width: "100%", marginRight: "100px"}}>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <Typography variant="h4">{editMode ? "Edit Bill Details" : "View Bill Details"}</Typography>
                <Button
                    variant="contained"
                    disabled={editMode}
                    color="primary"
                    onClick={(e) => {
                        e.preventDefault();
                        handleOpenDialog();
                    }}
                >
                    Create Vendor Invoice on Enkash
                </Button>
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogTitle>Create Vendor Invoice</DialogTitle>
                    <DialogContent>
                        <Box component="form" noValidate autoComplete="off">
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: 1,
                                gap: 1
                            }}>
                                <Box sx={{flex: 1}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Payment due date"
                                            name={"enkashPaymentDueDate"}
                                            required
                                            fullWidth
                                            minDate={dayjs(new Date())}
                                            margin="normal"
                                            sx={{mt: "20px"}}
                                            value={dayjs(billData.invoiceDueDate || null)}
                                            onChange={(newDate) => {
                                                handleFieldChange("invoiceDueDate", newDate.toISOString())
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Box sx={{flex: 1}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Enkash Invoice date"
                                            name={"enkashInvoiceDate"}
                                            required
                                            fullWidth
                                            margin="normal"
                                            sx={{mt: "20px"}}
                                            value={dayjs(billData.enkashInvoiceDate || null)}
                                            onChange={(newDate) => {
                                                handleFieldChange("enkashInvoiceDate", newDate.toISOString())
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Box sx={{flex: 1}}>
                                    <TextField
                                        type="number"
                                        disabled={!totalAmountNullInitally}
                                        value={billData.totalAmount}
                                        sx={{mt: "20px"}}
                                        onChange={handleDueAmountChange} label={"Total amount"}>
                                    </TextField>
                                </Box>
                            </Box>
                            <Typography variant="body2" color="textSecondary" sx={{mt: 1}}>
                                Total Payment due for the bill: {billData.paymentDuePercentage || 0}%<br/>
                                Total Amount
                                due: {((billData.paymentDuePercentage / 100) * billData.totalAmount).toFixed(2)} INR<br/>
                            </Typography>
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: 1,
                                gap: 1
                            }}>
                                <Box sx={{flex: 1}}>
                                    <TextField
                                        type="number"
                                        name="enkashPaymentPercentage"
                                        label={"Payment to be paid"}
                                        value={billData.enkashPaymentPercentage}
                                        disabled={isNull(billData.totalAmount)}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                                        }}
                                        InputLabelProps={{
                                            shrink: !(isNull(billData.enkashPaymentPercentage) || isNaN(billData.enkashPaymentPercentage)),
                                        }}
                                        margin="normal"
                                        fullWidth
                                        onChange={handleEnkashPercentageChange} placeholder={"Enter due amount %"}>
                                    </TextField>
                                </Box>
                                <Box sx={{flex: 1}}>
                                    <TextField
                                        type="number"
                                        value={enkashInvoiceAmount}
                                        disabled={isNull(billData.totalAmount)}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">₹</InputAdornment>
                                        }}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: !(isNull(enkashInvoiceAmount) || isNaN(enkashInvoiceAmount)),
                                        }}
                                        onChange={handleFirstPaymentAmountChange} label={"Payment to be paid"}>
                                    </TextField>
                                </Box>
                            </Box>
                            {enkashVendorSelector(!enkashVendorNullInitially, enkashVendorList, billData.enkashVendorId, handleEnkashVendorIdChange, handleVendorListSearchChange)}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleConfirmationOpen} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>

                <ConfirmationModal
                    open={confirmationOpen}
                    confirmationText={enkashInformationString}
                    onConfirm={handleEnkashInvoiceCreation}
                    onClose={handleConfirmationClose}
                />

                {
                    !editMode ? (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => {
                                e.preventDefault();
                                setEditMode(true);
                            }}
                        >
                            Start Editing Bill
                        </Button>
                    ) : (
                        <Button variant="contained" onClick={updateBillDetails} disabled={savingDetails || !editMode}>
                            {savingDetails ? <CircularProgress title={"Saving..."}/> : "Update Bill"}
                        </Button>
                    )
                }
            </Box>
            <Divider style={{marginTop: "10px", marginBottom: "30px"}}></Divider>
            <Grid container spacing={2} sx={{overflowY: "scroll"}}>
                <Grid item xs={8} sm={4}>
                    <div className={"card-box-shadow"}>
                        <Box display={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            {!editMode ?
                                billData.billName ? (
                                        <Typography variant='h5'>{billData.billName}
                                            <Tooltip title={"Copy bill ID"}>
                                                <ContentCopy
                                                    onClick={() => copyToClipboard(billId)}
                                                    sx={{
                                                        fontSize: 16,
                                                        marginLeft: "5px",
                                                        marginTop: "5px",
                                                        cursor: "pointer"
                                                    }}>
                                                </ContentCopy>
                                            </Tooltip>
                                        </Typography>
                                    ) :
                                    <Typography variant='h7'>
                                        Proforma Name: <b>{billData.proformaBillName}</b>
                                        <Tooltip title={"Copy bill ID"}>
                                            <ContentCopy
                                                onClick={() => copyToClipboard(billId)}
                                                sx={{
                                                    fontSize: 16,
                                                    marginLeft: "5px",
                                                    marginTop: "5px",
                                                    cursor: "pointer"
                                                }}>
                                            </ContentCopy>
                                        </Tooltip>
                                    </Typography>
                                : (
                                    <TextField
                                        label={"Invoice Number"}
                                        onChange={(event) => handleFieldChange("billName", event.target.value)}
                                        value={billData.billName}
                                        placeholder={"Enter invoice number"}
                                        defaultValue={billData.billName}
                                    />
                                )}
                            <Box style={{display: "flex", alignItems: "center"}}>
                                {getUserSelectionBox(editMode && isNull(createdByUserId), updatePaidBy, createdByUserId, allUsers, "Created By")}
                            </Box>
                        </Box>
                        <Box sx={{mt: 2}}
                             display={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            {editMode ? (
                                <TextField
                                    label={"Performa Invoice Number"}
                                    onChange={(event) => handleFieldChange("proformaBillName", event.target.value)}
                                    value={billData.proformaBillName}
                                    placeholder={"Enter performa invoice number"}
                                    defaultValue={billData.proformaBillName}
                                />
                            ) : null}
                        </Box>
                        <Box sx={{mt: 2, mb: 2}}>
                            {
                                billData.zoho_details ? <ZohoDetails zohoDetails={billData.zoho_details}/> : null
                            }
                        </Box>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 2,
                            padding: 1,
                            gap: 1
                        }}>
                            <Box sx={{flex: 1}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Bill due date"
                                        name={"billDueDate"}
                                        disabled={!editMode}
                                        value={dayjs(billData.paymentDueDate)}
                                        minDate={dayjs(new Date())}
                                        onChange={(newDate) => {
                                            handleFieldChange("paymentDueDate", newDate.toISOString())
                                        }}
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                padding: '4px',
                                            },
                                            '& .MuiInputBase-input': {
                                                padding: '4px',
                                            },
                                            '& .MuiInputLabel-root': {
                                                top: '-5px',
                                            },
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box sx={{flex: 1}}>
                                <TextField
                                    type="number"
                                    disabled={!editMode}
                                    value={billData.totalAmount}
                                    inputProps={{
                                        sx: {padding: "8px !important"}
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleDueAmountChange} label={"Total amount"}>
                                </TextField>
                            </Box>
                            <Box sx={{flex: 1}}>
                                <TextField
                                    type="number"
                                    name="paymentDuePercentage"
                                    label={"Payment Due"}
                                    disabled={!editMode}
                                    value={billData.paymentDuePercentage}
                                    inputProps={{
                                        sx: {padding: "8px !important"}
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                                    }}
                                    onChange={(e) => {
                                        handleFieldChange(e.target.name, e.target.value)
                                    }}>
                                </TextField>
                            </Box>
                        </Box>
                        <Box sx={{mb: 2}}>
                            {accountSelector(!editMode, accountList, billData.account, (newValue) => {
                                handleFieldChange("account", newValue)
                            }, handleAccountListSearchChange)}
                        </Box>
                        <Box sx={{mb: 2}}>
                            {batchNumberSelector(!editMode, batchList, billData.batchNumber, (newValue) => {
                                handleFieldChange("batchNumber", newValue)
                            }, handleBatchListSearchChange)}
                        </Box>
                        <Box sx={{mb: 2}}>
                            {getVendorSelectionComponent(zohoVendorList, !editMode, (newValue) => {
                                handleFieldChange("zohoVendor", newValue)
                            }, handleZohoVendorListSearchChange, setShowCreateVendorPopup, billData.zohoVendor)}
                        </Box>
                        {billData.enkashVendorId ?
                            <Box sx={{mb: 2}}>
                                {enkashVendorSelector(!enkashVendorNullInitially || !editMode, enkashVendorList, billData.enkashVendorId, handleEnkashVendorIdChange, handleVendorListSearchChange)}
                            </Box> : null}
                        <Box>
                            {editMode ? <Typography variant="h7" component="h4">Bill Files</Typography> : null}
                            <FilesListView files={billData.bill_files} uploaded={true} showRemoveIcon={editMode}
                                           removeHandler={(id) => {
                                               setBillFileDeleteMeta({
                                                   fileId: id,
                                                   confirmationModal: true
                                               })
                                           }}
                            />
                        </Box>
                        {editMode ?
                            <Box>
                                <Typography variant="h7" component="h4">Additional Files</Typography>
                                <FilesListView
                                    files={billData.additional_files}
                                    uploaded={true}
                                    showRemoveIcon={editMode}
                                    removeHandler={(id) => {
                                        setAdditionalFileDeleteMeta({
                                            fileId: id,
                                            confirmationModal: true
                                        });
                                    }}
                                />
                            </Box> : null}
                        <Divider sx={{mt: 2, mb: 2}}></Divider>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: 2,
                                gap: 2,
                            }}
                        >
                            <Box sx={{flex: 1}}>
                                <Box sx={{mb: 2}}>
                                    <FileUploadComponent
                                        text={"Attach bill files"}
                                        files={billData.new_bill_files}
                                        handleFileChange={updateNewBillFiles}
                                        allowFiles={"*"}
                                        showFiles={false}
                                        disabled={!editMode}
                                        index={"bill-files"}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{flex: 1}}>
                                <Box sx={{mb: 2}}>
                                    <FileUploadComponent
                                        text="Attach additional files"
                                        files={billData.new_additional_files}
                                        handleFileChange={updateNewAdditionalFiles}
                                        allowFiles="*"
                                        showFiles={false}
                                        disabled={!editMode}
                                        index={"additional-files"}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: 2,
                                gap: 2,
                                boxSizing: "unset",
                            }}
                        >
                            <Box sx={{
                                flex: 1,
                                boxSizing: "unset",
                            }}>
                                <FilesListView files={billData.new_bill_files ? billData.new_bill_files : []}
                                               removeHandler={(id) => {
                                                   removeNewBillFiles(id);
                                               }}
                                />
                            </Box>
                            <Box sx={{
                                flex: 1,
                                boxSizing: "unset",
                            }}>
                                <FilesListView
                                    files={billData.new_additional_files || []}
                                    removeHandler={(id) => {
                                        removeNewAdditionalFiles(id);
                                    }}
                                />
                            </Box>
                        </Box>
                    </div>
                </Grid>
                <Grid item xs={16} sm={8}>
                    <div className={"card-box-shadow"} style={{height: "100%"}}>
                        <Box sx={{maxHeight: "500px", overflowY: "scroll"}}>
                            {
                                billData.payments.map((payment, index) => (
                                    <PaymentFormCard
                                        expandCard={index === openPaymentCard}
                                        saved={true}
                                        index={index}
                                        onDeleteSavedPaymentEntry={() => {
                                            setPaymentDeleteMeta({
                                                paymentId: payment.id,
                                                confirmationModal: true
                                            })
                                        }}
                                        toggleCardExpand={() => toggleCardExpand(index)}
                                        handleVendorSelection={(val) => handleVendorNameSelection(index, val)}
                                        paymentModes={paymentModes} paymentData={payment} editMode={editMode}/>
                                ))
                            }
                        </Box>
                        <Divider sx={{mt: 2, mb: 2}}></Divider>
                        <Box sx={{maxHeight: "700px", overflowY: "auto"}}>
                            {
                                newPaymentEntries.map((payment, index) => (
                                    <PaymentFormCard
                                        index={index}
                                        paymentModes={paymentModes}
                                        paymentData={payment}
                                        additional_files={payment.additional_files}
                                        removePaymentEntry={() => removePaymentEntry(index)}
                                        handleAdditionalFilesChange={(files) => handleAdditionalFilesChange(index, files)}
                                        handleChange={(e) => handlePaymentFormChange(index, e)}
                                        handleVendorSelection={(val) => handleVendorNameSelection(index, val)}
                                        setGeneratedIRN={(val) => setGeneratedIRN(index, val)}
                                    />
                                ))
                            }
                            <Divider></Divider>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: 2,
                                    gap: 2,
                                }}
                            >
                                <Box sx={{flex: 1}}>
                                    <Button variant={"contained"} onClick={addNewPaymentEntry} disabled={!editMode}
                                            sx={{float: "center", mt: 2}} startIcon={<AddOutlined/>}> Click to add
                                        payment
                                        entry</Button>
                                </Box>
                                <Box sx={{flex: 1}}>
                                    <Button variant={"contained"} onClick={() => setOpenPaymentSelector(true)}
                                            disabled={!editMode}
                                            sx={{float: "center", mt: 2}} startIcon={<LinkIcon/>}> Click to link payment
                                        entry</Button>
                                </Box>
                            </Box>
                            <Dialog open={openPaymentSelector} onClose={() => setOpenPaymentSelector(false)}
                                    maxWidth="sm" fullWidth>
                                <DialogTitle>Select Payment's Transaction ID</DialogTitle>
                                <DialogContent>
                                    {paymentSelector(!(openPaymentSelector || editMode), paymentList, linkPaymentDetails, setLinkPaymentDetails, handlePaymentListSearchChange)}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setOpenPaymentSelector(false)} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={linkExistingPaymentEntry} color="primary">
                                        Add Payment
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Box>
                    </div>
                </Grid>
            </Grid>
            <ConfirmationModal
                open={paymentDeleteMeta.confirmationModal}
                confirmationText={"Are you sure you want to delete payment details?"}
                onConfirm={deleteSavedPaymentEntry}
                onClose={() => {
                    setPaymentDeleteMeta({
                        paymentId: null,
                        confirmationModal: false
                    })
                }}/>
            <ConfirmationModal
                open={billFileDeleteMeta.confirmationModal}
                confirmationText={"Are you sure you want to delete uploaded bill file?"}
                onConfirm={deleteBillFileEntry}
                onClose={() => {
                    setBillFileDeleteMeta({
                        fileId: null,
                        confirmationModal: false
                    })
                }}/>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1300}}
                open={loading}
                // onClick={handleClose}
            >
                <CircularProgress color="primary"/>
            </Backdrop>
            <CreateVendor open={showCreateVendorPopup} onClose={() => setShowCreateVendorPopup(false)}/>
        </Box>
    )
}