import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import {GET_BILL_DETAILS} from "../utils/consts";
import {axiosInstance} from "../utils/axiosConfig";
import {
    enkashVendorSelector,
    fetchAccountList,
    fetchAllUsersUtil,
    fetchEnkashVendorList,
    fetchVendorsList,
    getTableCellContent,
    getVendorSelectionComponent
} from "./utils";
import "./page_styles.css";
import {notifyError} from "../utils/display.notifications";
import {COLOR_MAP, isNull, notNull} from "../utils/utils";
import {useNavigate} from "react-router-dom";
import {ClearIcon} from "@mui/x-date-pickers";
import Divider from "@mui/material/Divider";
import {DatasetOutlined} from "@mui/icons-material";
import Backdrop from "@mui/material/Backdrop";

const BillDetailsTable = () => {
    const [bills, setBills] = useState([]);
    const [columns, setColumns] = useState([]);

    const [allUsers, setAllUsers] = useState([]);
    const [enkashVendorList, setEnkashVendorList] = useState([])
    const [zohoVendorList, setZohoVendorList] = useState([])
    const [enkashVendorListSearchMeta, setEnkashVendorListSearchMeta] = useState({
        searchQuery: ''
    });
    const [userZohoVendorListSearchMeta, setUserZohoVendorListSearchMeta] = useState({
        searchQuery: ''
    });
    const [attachedZohoVendorListSearchMeta, setAttachedZohoVendorListSearchMeta] = useState({
        searchQuery: ''
    });

    const [loading, setLoading] = useState(true);
    const userId = localStorage.getItem("user_id");
    const navigate = useNavigate();
    const [searchQueryDraft, setSearchQueryDraft] = useState(null);
    const [allAccounts, setAllAccounts] = useState([])

    const [tableViewMetas, setTableViewMetas] = useState(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.get('searchQuery')) {
            setSearchQueryDraft(params.get('searchQuery'))
        }

        return {
            pageNumber: parseInt(params.get('pageNumber')) || 0,
            pageSize: parseInt(params.get('pageSize')) || 25,
            sortBy: params.get('sortBy') || 'created_at',
            sortOrder: params.get('sortOrder') || 'desc',
            paidByFilter: params.get('paidByFilter') || userId || "all",
            billDueFilter: params.get('billDueFilter') || "all",
            accountFilter: params.get('accountFilter') || "all",
            enkashVendor: params.get("enkashVendor") || null,
            userZohoVendor: params.get("userZohoVendor") || null,
            attachedZohoVendor: params.get("attachedZohoVendor") || null,
            paymentAttachedFilter: params.get('paymentAttachedFilter') || "all",
            searchQuery: params.get("searchQuery") || null
        }
    });


    const updateURLParams = (newParams) => {
        const url = new URL(window.location.href);
        Object.keys(newParams).forEach(key => {
            if ((notNull(newParams[key]) && newParams[key] !== "all" && !key.includes("totalEntries") && key !== "userZohoVendor" && key !== "attachedZohoVendor") || key === "paidByFilter") {
                url.searchParams.set(key, newParams[key]);
            } else {
                url.searchParams.delete(key);
            }
        });
        window.history.pushState({}, '', url.toString());
    };


    const handleSortByChange = (newSortBy) => {
        const isAsc = newSortBy === tableViewMetas.sortBy && tableViewMetas.sortOrder === 'asc';
        const sortOrder = isAsc ? 'desc' : 'asc';
        setTableViewMetas({
            ...tableViewMetas,
            sortOrder: sortOrder,
            sortBy: newSortBy,
        });
    }
    const handleSearchQueryUpdate = (e) => {
        setSearchQueryDraft(e.target.value);
    }

    const handleSearchSubmit = (e) => {
        setTableViewMetas({
            ...tableViewMetas,
            searchQuery: searchQueryDraft
        })
    }

    const handleChangePageSize = (e) => {
        const size = parseInt(e.target.value, 10);
        setTableViewMetas({
            ...tableViewMetas,
            pageSize: size,
        });
    }

    const handlePageChange = (e, newPage) => {
        setTableViewMetas({
            ...tableViewMetas,
            pageNumber: newPage,
        });
    }

    const handleChangePaidByFilter = (e) => {
        setTableViewMetas({
            ...tableViewMetas,
            paidByFilter: e.target.value,
        });
    }

    const handleChangeAccountFilter = (e) => {
        setTableViewMetas({
            ...tableViewMetas,
            accountFilter: e.target.value,
        });
    }

    const handleBillDueFilterChange = (e) => {
        setTableViewMetas({
            ...tableViewMetas,
            billDueFilter: e.target.value,
        });
    }

    const handlePaymentAttachedFilterChange = (e) => {
        setTableViewMetas({
            ...tableViewMetas,
            paymentAttachedFilter: e.target.value,
        });
    }

    const tablesPaginatedObj = (
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={tableViewMetas.totalEntries}
            rowsPerPage={tableViewMetas.pageSize}
            page={tableViewMetas.pageNumber}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangePageSize}
        />
    );


    const fetchAllUsers = () => {
        fetchAllUsersUtil(setAllUsers);
    }

    const fetchBills = () => {
        const {
            pageNumber,
            pageSize,
            totalEntries,
            sortBy,
            sortOrder,
            billDueFilter,
            paidByFilter,
            searchQuery,
            accountFilter,
            paymentAttachedFilter,
            userZohoVendor,
            attachedZohoVendor,
            enkashVendor
        } = tableViewMetas;
        let queryString = `?page_number=${pageNumber + 1}&page_size=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}` +
            `&paidByFilter=${paidByFilter}&billDueFilter=${billDueFilter}&accountFilter=${accountFilter}` +
            `&paymentAttachedFilter=${paymentAttachedFilter}&userZohoVendorId=${userZohoVendor?.id || "all"}` +
            `&attachedZohoVendorName=${attachedZohoVendor?.name || "all"}&enkashVendorId=${enkashVendor || "all"}`;
        if (!isNull(searchQuery)) {
            queryString += `&searchQuery=${searchQuery}`;
        }

        setLoading(true);
        axiosInstance.get(GET_BILL_DETAILS + queryString)
            .then(resp => {
                setBills(resp.data.bill_details);
                setColumns(resp.data.columns);
                if (resp.data.page.total !== totalEntries) {
                    setTableViewMetas({
                        ...tableViewMetas,
                        totalEntries: resp.data.page.total
                    });
                }
                if (resp.data.page.current - 1 !== pageNumber) {
                    setTableViewMetas({
                        ...tableViewMetas,
                        pageNumber: resp.data.page.current - 1
                    });
                }
            })
            .catch(err => {
                console.log(err);
                notifyError("billstable", "Something went wrong in getting bill details, please retry");
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        fetchAllUsers();
        fetchAccountList(setAllAccounts);
    }, []);

    useEffect(() => {
        fetchVendorsList(setZohoVendorList, userZohoVendorListSearchMeta.searchQuery, userZohoVendorListSearchMeta.maxEntries);
    }, [userZohoVendorListSearchMeta]);

    useEffect(() => {
        fetchVendorsList(setZohoVendorList, attachedZohoVendorListSearchMeta.searchQuery, attachedZohoVendorListSearchMeta.maxEntries);
    }, [attachedZohoVendorListSearchMeta]);

    useEffect(() => {
        setLoading(true);
        fetchEnkashVendorList(setEnkashVendorList, enkashVendorListSearchMeta.searchQuery, false, setLoading);
    }, [enkashVendorListSearchMeta]);

    const handleEnkashVendorIdChange = (enkashVendor) => {
        let enkashVendorId = null;
        if (enkashVendor) {
            enkashVendorId = enkashVendor.company_id;
        }
        setTableViewMetas({
            ...tableViewMetas,
            enkashVendor: enkashVendorId,
        })
    }

    const handleUserZohoVendorIdChange = (userZohoVendor) => {
        setTableViewMetas({
            ...tableViewMetas,
            userZohoVendor: userZohoVendor,
        })
    }

    const handleAttachedZohoVendorIdChange = (attachedZohoVendor) => {
        setTableViewMetas({
            ...tableViewMetas,
            attachedZohoVendor: attachedZohoVendor,
        })
    }

    const handleVendorListSearchChange = (e) => {
        setEnkashVendorListSearchMeta({
            ...enkashVendorListSearchMeta,
            searchQuery: e.target.value,
        });
    }

    const handleUserZohoListSearchChange = (e) => {
        setUserZohoVendorListSearchMeta({
            ...userZohoVendorListSearchMeta,
            searchQuery: e.target.value,
        });
    }

    const handleAttachedZohoListSearchChange = (e) => {
        setAttachedZohoVendorListSearchMeta({
            ...attachedZohoVendorListSearchMeta,
            searchQuery: e.target.value,
        });
    }


    useEffect(() => {
        fetchBills();
        updateURLParams(tableViewMetas)
    }, [tableViewMetas]);

    let tableDataRows = (
        <div sx={{
            display: 'flex',
            width: "300px",
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center"
        }}>
            <DatasetOutlined/>
            <Typography variant="h6">
                No Data found.
            </Typography>
        </div>
    );
    if (bills.length > 0) {
        tableDataRows =
            bills.map((payment, index) => (
                <TableRow key={index} className={"tableRow"}
                          onDoubleClick={() => {
                              navigate(payment.edit_link);
                          }}>
                    {columns.map((column, index) => {
                        return (
                            <TableCell
                                key={index}
                                sx={{
                                    padding: 0, paddingLeft: 0.5, paddingBottom: "1px",
                                    border: "1px solid grey", maxWidth: column.width
                                }}>
                                {getTableCellContent(payment, column, index)}
                            </TableCell>
                        );
                    })}
                </TableRow>
            ))
    }
    return (
        <div>
            <Box style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <h1>Bills</h1>
                <Box
                    sx={{ml: 15, display: "flex", justifyContent: "space-between", alignItems: "center", width: "50%"}}>
                    <TextField
                        onChange={handleSearchQueryUpdate}
                        placeholder={"Search in transaction data"}
                        value={searchQueryDraft}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {searchQueryDraft && (
                                        <IconButton onClick={() => {
                                            setSearchQueryDraft('');
                                        }} edge="end">
                                            <ClearIcon/>
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                handleSearchSubmit(e);
                            }
                        }}
                        fullWidth>
                    </TextField>
                    <Button
                        onClick={handleSearchSubmit}
                        variant="contained" color="primary" sx={{ml: 3}}>
                        Search
                    </Button>
                </Box>
                {tablesPaginatedObj}
            </Box>
            <Box style={{display: "flex", justifyContent: "space-between", mt: 1}}>
                <FormControl sx={{minWidth: 150}}>
                    <InputLabel sx={{mr: 2}} id="paid-by-select">Paid By</InputLabel>
                    <Select
                        labelId="paid-by-select"
                        label="Paid By"
                        value={tableViewMetas.paidByFilter}
                        onChange={handleChangePaidByFilter}
                        inputProps={{
                            sx: {padding: "8px !important"}
                        }}
                    >
                        <MenuItem key={"all"} value={"all"}>All</MenuItem>
                        <MenuItem key={"none"} value={"none"}>None</MenuItem>
                        <Divider></Divider>
                        {
                            allUsers.map(user => (
                                <MenuItem key={user.id} value={user.id}>
                                    {user.username}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <FormControl sx={{minWidth: 150}}>
                    <InputLabel sx={{mr: 2}} id="account-select">Account</InputLabel>
                    <Select
                        labelId="account-select"
                        label="Account"
                        value={tableViewMetas.accountFilter}
                        onChange={handleChangeAccountFilter}
                        inputProps={{
                            sx: {padding: "8px !important"}
                        }}
                    >
                        <MenuItem key={"all"} value={"all"}>All</MenuItem>
                        <MenuItem key={"none"} value={"none"}>None</MenuItem>
                        <Divider></Divider>
                        {
                            allAccounts.map(account => (
                                <MenuItem key={account.id} value={account.id}>
                                    {account.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <FormControl sx={{minWidth: 200}}>
                    {enkashVendorSelector(false, enkashVendorList, tableViewMetas.enkashVendor, handleEnkashVendorIdChange, handleVendorListSearchChange, null, "small")}
                </FormControl>
                <FormControl sx={{minWidth: 200}}>
                    {getVendorSelectionComponent(zohoVendorList, false, handleUserZohoVendorIdChange, handleUserZohoListSearchChange, null, tableViewMetas.userZohoVendor, "small", false, "Search User Vendor")}
                </FormControl>
                <FormControl sx={{minWidth: 200}}>
                    {getVendorSelectionComponent(zohoVendorList, false, handleAttachedZohoVendorIdChange, handleAttachedZohoListSearchChange, null, tableViewMetas.attachedZohoVendor, "small", false, "Search Attached Vendor")}
                </FormControl>
                <FormControl sx={{minWidth: 150}}>
                    <InputLabel id="payment-attached-label">Any Payments Done</InputLabel>
                    <Select
                        labelId="payment-attached-label"
                        name="billAttached"
                        value={tableViewMetas.paymentAttachedFilter}
                        label="Payment Attached"
                        inputProps={{
                            sx: {padding: "8px !important"}
                        }}
                        onChange={handlePaymentAttachedFilterChange}
                    >
                        <MenuItem key={"all"} value={"all"}>All</MenuItem>
                        <MenuItem key={"yes"} value={"yes"}>Yes</MenuItem>
                        <MenuItem key={"no"} value={"no"}>No</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{minWidth: 150}}>
                    <InputLabel id="bill-due-label">Bill Due</InputLabel>
                    <Select
                        labelId="bill-due-label"
                        name="billDue"
                        value={tableViewMetas.billDueFilter}
                        label="Bill due"
                        inputProps={{
                            sx: {padding: "8px !important"}
                        }}
                        onChange={handleBillDueFilterChange}
                    >
                        <MenuItem key={"all"} value={"all"}>All</MenuItem>
                        <MenuItem key={"yes"} value={"yes"}>Yes</MenuItem>
                        <MenuItem key={"no"} value={"no"}>No</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <TableContainer component={Paper} sx={{mt: 2}}>
                <Table>
                    <TableHead className={"tableHeader"}>
                        {columns.map((column, index) => (
                            <TableCell key={index}
                                       sx={{
                                           maxWidth: column.width, padding: 0, paddingLeft: "10px",
                                           paddingBottom: "2px", border: "1px solid grey",
                                           backgroundColor: column.color_group ? COLOR_MAP[column.color_group] : "default"
                                       }}>
                                <TableSortLabel
                                    style={{padding: 0}}
                                    active={tableViewMetas.sortBy === column.key}
                                    direction={tableViewMetas.sortBy === column.key ? tableViewMetas.sortOrder : 'asc'}
                                    onClick={() => handleSortByChange(column.key)}
                                    disabled={isNull(column.sortable) ? false : !column.sortable}
                                >
                                    <Tooltip title={column.tooltip_text ? column.tooltip_text : column.name}>
                                        {column.name}
                                    </Tooltip>
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </TableHead>
                    <TableBody>
                        {tableDataRows}
                    </TableBody>
                </Table>
            </TableContainer>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1}}
                open={loading}
                // onClick={handleClose}
            >
                <CircularProgress color="primary"/>
            </Backdrop>

        </div>
    );
};

export default BillDetailsTable;
